<template>
  <div class="main">
    <Navigation :spreadProduct="'#359efe'"/>

    <div class="loveImg">
      <img :src="bannerImg" />
      <div class="newsEnglish">{{$globalVar.menu2_c_2_title_en1}}&nbsp;{{$globalVar.menu2_c_2_title_en2}}</div>
      <div class="newsChinese">{{$globalVar.menu2_c_2 }}</div>
    </div>

    <div class="content">
      <!-- 设计理念 -->
      <div class="same DesignConcept">
        <div class="conceptTop">
          <div class="leftText">
            <img class="DesignBg" :src="loveSeriesImg" alt="" />
            <div class="Design">
              <div class="Title-C nameC">设计理念</div>
              <div class="Title-E nameE">DESIGN CONCEPT</div>
              <div class="line"></div>
              <div class="DetailText detail">
                <p class="pcShow loveStart">“让爱从细胞深处开始”</p>
                <p class="pcShow">
                  纷繁复杂的生物圈 有一条条无形的碳链把我们相连
                </p>
                <p class="mobileShow">纷繁复杂的生物圈</p>
                <p class="mobileShow">有一条条无形的碳链把我们相连</p>
                <p>设计师将浪漫的DNA 动人的碳链</p>
                <p>璀璨夺目的水晶以实物的形式唯美定格</p>
                <p>把两个人的DNA放在一起,</p>
                <p>让载体伴随着生命的能量绽放,</p>
                <p>一切都会是爱的模样</p>
                <p>从细胞到奇点情感终将幻化成无限循环的双螺旋</p>
              </div>
            </div>
          </div>
          <div class="rightImg mobileImg">
            <img :src="carbonChainImg" alt="" />
          </div>
        </div>
        <div class="conceptBottom">
          <div class="SpecialGift">
            <div class="shape">
              <img src="../../assets/img/product/engraved.png" alt="" />
            </div>
            <div class="DetailText meaning">
              <p>火漆印经典铭刻</p>
              <p>爱意如火</p>
              <p>生生不息</p>
            </div>
          </div>
          <div class="SpecialGift">
            <div class="shape fawn">
              <img src="../../assets/img/product/fawn.png" alt="" />
            </div>
            <div class="DetailText meaning">
              <p>小鹿图案采集信封</p>
              <p>携手一生</p>
              <p>一路由你</p>
            </div>
          </div>
          <div class="SpecialGift">
            <div class="shape">
              <img src="../../assets/img/product/lights.png" alt="" />
            </div>
            <div class="DetailText meaning">
              <p>精致红色彩灯礼盒</p>
              <p>所念之间</p>
              <p>星河皆是你</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separate"></div>

      <!-- 产品展示 -->
      <div class="same ProductShow">
        <div class="Title-C productNameC">产品展示</div>
        <div class="Title-E productNameE pcShow">PRODUCT CLASSIFICATION</div>
        <div class="Title-E productNameE mobileShow">PRODUCT DISPLAY</div>
        <div class="productImg">
          <div>
            <img :src="honeyPot" alt="" />
            <ol>
              <p class="descTitle">DNA小蜜罐*2</p>
              <p>放置你和TA的DNA</p>
            </ol>
          </div>
          <div class="falling">
            <ol class="pcShow">
              <p class="descTitle">男士钛钢项链*1</p>
              <p>把你的TA戴在身边</p>
            </ol>
            <img :src="menNecklace" alt="" />
            <ol class="mobileShow">
              <p class="descTitle">男士钛钢项链*1</p>
              <p>把你的TA戴在身边</p>
            </ol>
          </div>
          <div class="mobileZ">
            <img :src="missNecklace" alt="" />
            <ol class="mobileY">
              <p class="descTitle">女士925银镀18k金项链*1</p>
              <p>特别的礼物只送给最特别的你</p>
            </ol>
          </div>
          <div class="falling mobileZ mobileZui">
            <ol class="pcShow">
              <p class="descTitle">水晶抱抱熊*1</p>
              <p>守护TA的DNA</p>
            </ol>
            <img :src="bruin" alt="" />
            <ol class="mobileShow">
              <p class="descTitle">男士钛钢项链*1</p>
              <p>把你的TA戴在身边</p>
            </ol>
          </div>
        </div>
      </div>

      <!-- <div class="separate"></div> -->

      <!-- 包装展示
      <div class="sames PackShow">
        <div class="Title-C productNameC">包装展示</div>
        <div class="Title-E productNameE pcShow">PACKAGING CLASSIFICATION</div>
        <div class="Title-E productNameE mobileShow">PACKAGING DISPLAY</div>
        <div class="aaa">
          <div class="PackLeft">
            <img src="../../assets/img/product/love/lanternBox.png" alt="" />
            <p class="descTitle">精致红色彩灯礼盒</p>
            <p>
              所有的项目被装在一个红色的礼盒中，开盒之后按下开关，灯光瞬间亮起，一刻的浪漫，持久的感动。
            </p>
          </div>
          <div class="PackRight">
            <div class="RightTop">
              <div class="line"></div>
              <div class="top-left">
                <p class="descTitle">抱抱熊</p>
                <p>
                  晶莹剔透，八面玲珑；小熊面带淡淡的微笑，给人以愉悦的视觉享受，手捧所爱之人的DNA，好像环抱炙热的爱情；可爱萌动，无可替代。
                </p>
              </div>
              <div class="top-right">
                <img
                  src="../../assets/img/product/love/bruinBox.png"
                  alt=""
                />
              </div>
            </div>
            <div class="RightBottom">
              <img src="../../assets/img/product/love/giftBox.png" alt="" />
            </div>
          </div>
        </div>
      </div> -->

      <!-- 技术优势 -->
      <div class="Science">
        <div class="Title-C productNameC">技术优势</div>
        <div class="Title-E productNameE pcShow">FAMILY SERIES</div>
        <div class="Title-E productNameE mobileShow">TECHNICAL ADVANTAGES</div>
        <div class="ScienceDetails">
          <div class="introduce">
            <img src="../../assets/img/product/love/superiority1.png" alt="" />
            <div>
              <p>基因大数据的专业解读</p>
              <p>生命和爱赋予新的内涵</p>
            </div>
          </div>
          <div class="introduce">
            <img src="../../assets/img/product/love/superiority2.png" alt="" />
            <div>
              <p>资深情感导师定制完成</p>
              <p>彼此更了解 相处更简单</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 定制礼遇 -->
      <div class="same custom">
        <div class="Title-C productNameC">定制礼遇</div>
        <div class="Title-E productNameE pcShow">FRIENDSHIP SERIES</div>
        <div class="Title-E productNameE mobileShow">CUSTOM COURTESY</div>
        <div class="mobileShow warm">— 初见的美好 · 再见的温暖 —</div>
        <!-- 电脑端显示 -->
        <div class="pcShow">
          <div class="process">
            <div>
              <img src="../../assets/img/product/love/process1.png" alt="" />
              <p>签收采集盒</p>
            </div>
            <div class="PRight">
              <img src="../../assets/img/product/love/right.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/product/love/process2.png" alt="" />
              <p>样本采集</p>
            </div>
            <div class="PRight">
              <img src="../../assets/img/product/love/right.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/product/love/process3.png" alt="" />
              <p>回寄样本</p>
            </div>
            <div class="PRight">
              <img src="../../assets/img/product/love/right.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/product/love/process4.png" alt="" />
              <p>样本质检</p>
            </div>
          </div>
          <div class="proBottom">
            <img src="../../assets/img/product/love/bottom.png" alt="" />
          </div>
          <div class="process process2">
            <div>
              <img src="../../assets/img/product/love/process5.png" alt="" />
              <p>基因检测</p>
            </div>
            <div class="PLeft">
              <img src="../../assets/img/product/love/right.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/product/love/process6.png" alt="" />
              <p>情书定制</p>
            </div>
            <div class="PLeft">
              <img src="../../assets/img/product/love/right.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/product/love/process7.png" alt="" />
              <p>礼品定制</p>
            </div>
            <div class="PLeft">
              <img src="../../assets/img/product/love/right.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/product/love/process8.png" alt="" />
              <p>礼品寄送</p>
            </div>
          </div>
        </div>

        <!-- 移动端显示 -->
        <div class="mobileShow">
          <div class="DirectionRight">
            <div>
              <img src="../../assets/img/product/love/process1.png" alt="" />
              <p>签收采集盒</p>
            </div>
            <div class="toRight">
              <img src="../../assets/img/product/love/right.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/product/love/process2.png" alt="" />
              <p>样本采集</p>
            </div>
          </div>
          <div class="toBottomR">
            <img src="../../assets/img/product/love/bottom.png" alt="" />
          </div>
          
          <div class="DirectionLeft">
            <div>
              <img src="../../assets/img/product/love/process3.png" alt="" />
              <p>回寄样本</p>
            </div>
            <div class="toRight">
              <img src="../../assets/img/product/love/right.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/product/love/process4.png" alt="" />
              <p>样本质检</p>
            </div>
          </div>
          <div class="toBottomL">
            <img src="../../assets/img/product/love/bottom.png" alt="" />
          </div>
          <div class="DirectionRight">
            <div>
              <img src="../../assets/img/product/love/process5.png" alt="" />
              <p>基因检测</p>
            </div>
            <div class="toRight">
              <img src="../../assets/img/product/love/right.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/product/love/process6.png" alt="" />
              <p>情书定制</p>
            </div>
          </div>
          <div class="toBottomR">
            <img src="../../assets/img/product/love/bottom.png" alt="" />
          </div>
          <div class="DirectionLeft">
            <div>
              <img src="../../assets/img/product/love/process7.png" alt="" />
              <p>礼品定制</p>
            </div>
            <div class="toRight">
              <img src="../../assets/img/product/love/right.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/product/love/process8.png" alt="" />
              <p>礼品寄送</p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../../components/Navigation.vue";

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      bannerImg: require("@/assets/img/product/love/banner.jpg"), // 爱情系列大图
      loveSeriesImg: require("@/assets/img/product/loveSeries.png"),
      carbonChainImg: require("@/assets/img/product/concept.jpg"), // 基因碳链图片
      honeyPot: require("@/assets/img/product/love/honeyPot.png"), // 蜜罐图片
      menNecklace: require("@/assets/img/product/love/menNecklace.png"), // 男士项链图片
      missNecklace: require("@/assets/img/product/love/missNecklace.png"), // 女士项链图片
      bruin: require("@/assets/img/product/love/bruin.png"), // 抱抱熊图片
      // 包装展示
      lanternBox: require("@/assets/img/product/love/lanternBox.png"),
      bruinBox: require("@/assets/img/product/love/bruinBox.png"),
      giftBox: require("@/assets/img/product/love/giftBox.png"),
    };
  },
  mounted() {
   
    // 移动端图片
    if (window.screen.width >= 320 && window.screen.width <= 1023) {
      this.loveSeriesImg = require("@/assets/img/h5/product/loveSeries.jpg");
      // 产品展示
      this.honeyPot = require("@/assets/img/h5/product/love/honeyPot.png"); // 蜜罐图片
      this.menNecklace = require("@/assets/img/h5/product/love/menNecklace.png"); // 男士项链图片
      this.missNecklace = require("@/assets/img/h5/product/love/missNecklace.png"); // 女士项链图片
      this.bruin = require("@/assets/img/h5/product/love/bruin.png"); // 抱抱熊图片
      // 包装展示
      this.lanternBox = require("@/assets/img/h5/product/love/lanternBox.png");
      this.bruinBox = require("@/assets/img/h5/product/love/bruinBox.png");
      this.giftBox = require("@/assets/img/h5/product/love/giftBox.png");
    }
  },
};
</script>

<style lang="less">
.main {
  width: 100%;
  background-color: #fff;

  .loveImg {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .newsEnglish {
      font-size: 0.185rem;
      font-weight: 400;
      letter-spacing: 0.08rem;
      position: absolute;
      top: 1.37rem;
      left: 50%;
      transform: translateX(-50%);
      color: rgba(255, 255, 255, 1);
    }
    .newsChinese {
      font-size: 0.2rem;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 1.74rem;
      left: 4.55rem;
    }
  }

  @media (min-width: 320px) and (max-width: 1023px) {
    .loveImg {
      position: relative;
      img {
        width: 100%;
        height: 202px;
      }
      .newsEnglish {
        position: absolute;
        top: 1.5rem;
        left: 50%;
      transform: translateX(-50%);
      }
      .newsChinese {
        position: absolute;
        top: 1.9rem;
        left: 3.2rem;
      }
    }

    // 主要内容
    .content {
      position: relative;
      // 中文小标题
      .Title-C {
        font-size: 0.5rem;
        font-weight: bold;
        color: #333333;
      }
      // 英文小标题
      .Title-E {
        opacity: 0.5;
        color: #000000;
        font-size: 0.34rem;
        font-weight: 500;
        padding: 0.2rem 0 0.15rem 0;
      }
      // 详细描述的文字
      .DetailText {
        text-align: left;
        font-size: 0.285rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.59rem;
        padding: 0.23rem 0;
      }
      // 敬请期待
      .expect {
        text-align: center;
        padding-top: 0.52rem;
        font-size: 0.34rem;
        font-weight: 500;
        opacity: 0.5;
      }

      // 分隔线
      .separate {
        height: 0.2rem;
        background-color: #f3f2f2;
      }
      .pcShow {
        display: none;
      }

      .productNameC,
      .productNameE {
        text-align: center;
      }

      // 设计理念
      .DesignConcept {
        position: relative;
        padding-top: 0.6rem;
        .conceptTop {
          display: flex;
          flex-direction: column;
          .leftText {
            .DesignBg {
              position: absolute;
              z-index: 50;
              left: 1.63rem;
              height: 1.3rem;
            }
            .Design {
              position: relative;
              z-index: 100;
              padding: 1rem 0.3rem 0.3rem;
              text-align: center;
              .nameE {
                padding-bottom: 0.31rem;
              }
              .line {
                float: right;
                width: 29%;
                height: 2px;
                background-color: #efefef;
              }
              .detail {
                text-align: center;
              }
            }
          }
          .mobileImg {
            img {
              width: 7.5rem;
              height: 6.45rem;
            }
          }
        }
        .conceptBottom {
          display: flex;
          padding: 0.6rem 0.3rem 0.5rem;
          .SpecialGift {
            flex: auto;
            .shape {
              height: 1.485rem;
              text-align: center;
              background: url("../../assets/img/h5/product/specialBg.png")
                no-repeat;
              background-size: 1.48rem 1.48rem;
              background-position: center;
              img {
                width: 0.7rem;
                height: 0.75rem;
                padding-top: 0.33rem;
              }
            }
            .meaning {
              text-align: center;
              font-size: 0.24rem;
              color: #333333;
              line-height: 0.51rem;
            }
          }
        }
      }

      // 产品展示
      .ProductShow {
        padding: 1.1rem 0.3rem;
        .productImg {
          display: flex;
          flex-wrap: wrap;
          padding-top: 0.4rem;
          div {
            flex: 1;
            padding: 0 0.05rem;
            text-align: center;
            ol {
              padding-top: 0.35rem;
              p {
                font-size: 0.25rem;
                line-height: 0.5rem;
              }
              .descTitle {
                font-weight: bold;
                font-size: 0.287rem;
                padding-bottom: 0.05rem;
              }
            }

            img {
              width: 3.34rem;
            }
          }
          .mobileZ {
            padding-top: 0.7rem;
            .mobileY {
              padding: 0;
            }
          }
        }
      }

      // 包装展示
      .PackShow {
        padding: 1.1rem 0.3rem 0.45rem;
        .descTitle {
          padding: 0.45rem 0 0.2rem;
          font-weight: bold;
          font-size: 0.36rem;
        }
        .PackLeft {
          padding: 0.4rem 0;
          img {
            width: 100%;
          }

          p {
            line-height: 0.44rem;
          }
        }
        .PackRight {
          img {
            width: 100%;
          }
          .RightTop {
            display: flex;
            padding-bottom: 0.5rem;
            .top-left,
            .top-right {
              flex: 1;
            }
            .top-left {
              p {
                line-height: 0.39rem;
              }
            }
          }
        }
      }

      // 技术优势
      .Science {
        background: url("../../assets/img/h5/product/love/science.png")
          no-repeat;
        background-size: 100% 100%;
        padding: 0.7rem 0.3rem 0.7rem;
        position: relative;
        color: #fff;
        .productNameC,
        .productNameE {
          color: #fff;
        }
        .productNameE {
          opacity: 0.9;
        }
        .ScienceDetails {
          display: flex;
          text-align: center;
          padding-top: 0.2rem;
          .introduce {
            flex: 1;
            img {
              width: 1.6rem;
            }
            div {
              padding-top: 0.2rem;
              p {
                line-height: 0.4rem;
                font-size: 0.26rem;
              }
            }
          }
        }
      }

      // 定制礼遇
      .custom {
        padding: 1.1rem 0 0.7rem;
        .warm {
          text-align: center;
          font-size: 0.385rem;
          padding: 0.4rem 0;
        }

        .mobileShow {
          .DirectionRight,.DirectionLeft {
            display: flex;
            text-align: center;
            div {
              flex: 1;
              img {
                width: 1.7rem;
              }
              p {
                line-height: 0.65rem;
                font-size: 0.28rem;
              }
            }
            .toRight {
              padding-top: 0.57rem;
              img {
                width: 0.6rem;
              }
            }
          }
          // 方向箭头
          .toBottomR {
            padding: 0.4rem 1.8rem 0 0;
            text-align: right;
            img {
              width: 0.6rem;
            }
          }
          .toBottomL {
            padding: 0.4rem 0 0 1.77rem;
            text-align: left;
            img {
              width: 0.6rem;
            }
          }
          .DirectionLeft {
            flex-direction: row-reverse;
            .toRight {
              img {
                transform: rotate(180deg);
              }
            }
          }

        }
      }
      .custom .mobileShow > div {
        padding: 0.3rem 1.23rem 0;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 374px) {
    .content {
      // 包装展示
      .PackShow {
        .descTitle {
          padding: 0.5rem 0 0.2rem;
        }
        .PackRight {
          .RightTop {
            .top-right {
              padding-top: 0.2rem;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    // 主要内容
    .content {
      margin-top: 0.13rem;
      position: relative;
      .same {
        width: 80%;
        margin: 0 auto;
      }
      .sames {
        width: 85%;
        margin: 0 auto;
      }

      // 系列标题
      .Title {
        font-size: 0.375rem;
        font-weight: bold;
        margin-left: -0.07rem;
      }
      // 中文小标题
      .Title-C {
        font-size: 0.2604rem;
        font-weight: bold;
      }
      // 英文小标题
      .Title-E {
        opacity: 0.5;
        font-size: 0.1354rem;
        font-weight: 500;
        padding: 0.14rem 0 0.15rem 0.02rem;
      }
      // 详细描述的文字
      .DetailText {
        text-align: left;
        font-size: 0.1145rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.195rem;
      }

      .mobileShow {
        display: none;
      }

      // 设计理念
      .DesignConcept {
        position: relative;
        padding-bottom: 0.2rem;
        // 上部分
        .conceptTop {
          .leftText {
            display: inline-block;
            .DesignBg {
              width: 6.979rem;
              height: 0.8125rem;
              margin: 0.84rem 0 0 0.05rem;
            }
            .Design {
              padding-left: 0.84rem;
              position: relative;
              .nameC {
                color: #333333;
                line-height: 0.234rem;
                position: absolute;
                top: -0.2rem;
              }
              .nameE {
                color: #000000;
              }
              .loveStart {
                font-weight: bold;
                font-size: 0.14rem;
                padding-bottom: 0.06rem;
              }
            }
          }
          .rightImg {
            display: inline-block;
            position: absolute;
            top: 0.25rem;
            right: 0;
            img {
              width: 3.354rem;
              height: 3.354rem;
            }
          }
        }

        // 下部分
        .conceptBottom {
          display: flex;
          padding: 0.5rem 0 0 0.84rem;
          .SpecialGift {
            flex: auto;
            width: 0.953rem;
            .shape {
              width: 0.953rem;
              height: 0.953rem;
              text-align: center;
              background-image: url("../../assets/img/product/specialBg.png");
              background-size: 100%;
              img {
                width: 0.37rem;
                height: 0.4219rem;
                margin-top: 0.2335rem;
                line-height: 0.4219rem;
              }
            }
            .meaning {
              width: 0.953rem;
              text-align: center;
              padding-top: 0.1927rem;
            }
            .fawn {
              img {
                width: 0.4219rem;
                height: 0.4219rem;
              }
            }
          }
        }
      }

      // 产品展示
      .ProductShow {
        padding: 0.5rem 0 0.3rem;
        .productNameC,
        .productNameE {
          text-align: center;
          margin-right: 0.56rem;
        }
        .productImg {
          display: flex;
          padding-top: 0.21rem;
          div {
            flex: 1;
            img {
              display: block;
            }
            ol {
              .descTitle {
                font-weight: bold;
                font-size: 0.13rem;
              }
              p {
                text-align: center;
                line-height: 0.19rem;
                padding-right: 0.23rem;
                font-size: 0.09rem;
              }
            }
          }
          .falling {
            padding-top: 0.23rem;
          }
        }
      }

      // 包装展示
      .PackShow {
        padding: 0.65rem 0 0.1rem;
        .productNameC,
        .productNameE {
          padding-left: 1rem;
        }
        .PackLeft {
          width: 600px;
          display: inline-block;
          padding-top: 0.05rem;
          .descTitle {
            padding: 0.22rem 0 0.08rem;
            font-weight: bold;
            font-size: 0.13rem;
          }
          p {
            width: 2.5rem;
            font-size: 0.1rem;
            line-height: 0.16rem;
          }
        }
        .PackRight {
          display: inline-block;
          position: relative;
          margin-top: 0.13rem;
          .RightTop {
            position: relative;
            padding-left: 0.25rem;
            .line {
              width: 1px;
              height: 0.42rem;
              display: inline-block;
              background-color: rgba(0, 0, 0, 0.25);
              position: relative;
              top: -0.37rem;
            }
            .top-left,
            .top-right {
              display: inline-block;
            }
            .top-left {
              width: 360px;
              padding-left: 0.09rem;

              .descTitle {
                font-weight: bold;
                font-size: 0.13rem;
                padding-bottom: 0.09rem;
              }
              p {
                font-size: 0.1rem;
                line-height: 0.15rem;
              }
            }
            .top-right {
              padding-left: 0.25rem;
              position: relative;
              top: -0.6rem;
            }
          }
          .RightBottom {
            position: relative;
            top: -0.4rem;
          }
        }
      }

      // 技术优势
      .Science {
        background: url("../../assets/img/product/love/science.jpg") no-repeat;
        height: 4rem;
        padding: 0.1rem 0 0.25rem;
        position: relative;
        .productNameC,
        .productNameE {
          color: #fff;
          text-align: center;
          margin-right: 0.56rem;
        }
        .productNameC {
          position: relative;
          top: 0.58rem;
        }
        .productNameE {
          position: relative;
          top: 0.55rem;
        }
        .ScienceDetails {
          width: 76%;
          position: relative;
          top: 1rem;
          color: #fff;
          display: flex;
          padding-left: 1.1rem;
          .introduce {
            display: inline-block;
            text-align: center;
            flex: auto;
            div {
              padding-top: 0.18rem;
              font-size: 0.1rem;
              p {
                line-height: 0.18rem;
              }
            }
          }
        }
      }

      // 定制礼遇
      .custom {
        // padding: 0.4rem 0;
        padding: 0 0;
        .productNameC,
        .productNameE {
          text-align: center;
          margin-right: 0.56rem;
        }
        .process {
          display: flex;
          padding: 0.2rem 0;
          font-size: 0.1rem;
          div {
            flex: auto;
            text-align: center;
            p {
              line-height: 0.3rem;
            }
          }
          // 流程箭头
          .PRight,
          .PLeft {
            margin-top: 0.4rem;
          }
          .PLeft {
            img {
              transform: rotate(180deg);
            }
          }
        }
        .process2 {
          display: flex;
          flex-direction: row-reverse;
        }
        .proBottom {
          text-align: right;
          padding-right: 0.6rem;
        }
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1680px) {
    .content {
      .Title-C {
        font-size: 0.2rem;
      }
      .ProductShow {
        .productImg {
          div {
            img {
              width: 1.6rem;
            }
          }
        }
      }
      .PackShow {
        .aaa {
          display: flex;
        }
        .PackLeft {
          flex: 1;
          img {
            width: 100%;
          }
          
        }
        .PackRight {
          flex: 1;
          .RightTop {
            .top-left {
              width: 2rem;
            }
            .top-right {
              img {
                width: 1.5rem;
              }
            }
          }
          .RightBottom {
            img {
              width: 4.7rem;
              height: 100%;
            }
          }
        }
      }
      .Science {
        background: url("../../assets/img/product/love/science.jpg") no-repeat;
        background-size: 100% 3.8rem;
        padding: 0.1rem 0 0.25rem;
        position: relative;
        .ScienceDetails {
          position: relative;
          top: 0.85rem;
          .introduce {
            img {
              width: 0.9rem;
            }
            div {
              font-size: 0.11rem;
              p {
                line-height: 0.23rem;
              }
            }
          }
        }
      }
      .custom {
        // margin-top: -3.3rem;
        .process {
          div {
            img {
              width: 1rem;
            }
            p {
              font-size: 0.15rem;
              padding-top: 0.05rem;
            }
          }
          .PRight,
          .PLeft {
            margin-top: 0.36rem;
          }
          .PRight,.PLeft {
            img {
              width: 0.35rem;
            }
          }
        }
        .proBottom {
          padding-right: 0.52rem;
          img {
            width: 0.291rem;
          }
        }
      }
    }
  }
  
  @media (min-width: 1920px) {
    .content {
      .Title-C {
        font-size: 0.2rem;
      }
      .Science {
        height: 4.15rem;
      }
    }
  }
}
</style>
